import React from 'react';

const PreLoader = () => {
  return (
    <>
    <h2 className='pre-loader'>Loading...</h2>
    </>
  );
}

export default PreLoader;
